import React, { useState } from 'react';
import { motion } from 'framer-motion';
import SEO from '../components/seo';

const Services = () => {
	const [ current, setCurrent ] = useState(0);

	return (
		<section className="services">
			<SEO
				title="Services"
				description=""
			/>

			<h2>Services</h2>
			<div className="services-tabs">
				<div className={current === 0 ? 'active' : ''} onClick={() => setCurrent(0)}>Management et organisation</div>
				<div className={current === 1 ? 'active' : ''} onClick={() => setCurrent(1)}>Accompagnement et consulting</div>
				<div className={current === 2 ? 'active' : ''} onClick={() => setCurrent(2)}>Projets et événements</div>
			</div>

			<div style={{ position: 'relative', width: '100%', marginTop: '5vh' }}>
				<motion.div
					style={{ position: 'absolute' }}
					initial={{ y: 100, opacity: 0 }}
					animate={{
						y: current === 0 ? 0 : 100,
						opacity: current === 0 ? 1 : 0
					}}
				>
					<h3 style={{ fontSize: 40 }}>Management et organisation</h3>
					<h3 style={{ fontSize: 40, marginBottom: 0 }}>1.</h3>
					<p>Concilio intervient ponctuellement ou sur une durée plus longue pour soutenir les institutions lors de la définition de leur stratégie et la mise en place opérationnelle de celle-ci, jouant le rôle de « manager ad interim », apte à identifier les problématiques et à trouver avec le client des solutions adaptées et durables. Définition de la stratégie, rédaction de documents, organisation et suivi de workshop, suivi RH ou coordination avec les parties prenantes font parties des prestations principales proposées.</p>
				</motion.div>

				<motion.div
					style={{ position: 'absolute' }}
					initial={{ y: 100, opacity: 0 }}
					animate={{
						y: current === 1 ? 0 : 100,
						opacity: current === 1 ? 1 : 0
					}}
				>
					<h3 style={{ fontSize: 40 }}>Accompagnement et consulting</h3>
					<h3 style={{ fontSize: 40, marginBottom: 0 }}>2.</h3>
					<p>Concilio accompagne des cadres et collaborateur.trices en mettant à disposition son expertise en matière de management et d’organisation. Elle soutient les réflexions des personnes en charge, notamment lors de réorganisations nécessitant un suivi RH (change management) et leur propose des solutions pragmatiques et durables, adaptées à la culture et à l’environnement institutionnel.</p>
					<p>Concilio réalise également des audits ponctuels, permettant aux décideurs.</p>
				</motion.div>

				<motion.div
					style={{ position: 'absolute' }}
					initial={{ y: 100, opacity: 0 }}
					animate={{
						y: current === 2 ? 0 : 100,
						opacity: current === 2 ? 1 : 0
					}}
				>
					<h3 style={{ fontSize: 40 }}>Projets et événements</h3>
					<h3 style={{ fontSize: 40, marginBottom: 0 }}>3.</h3>
					<p>Concilio a à son actif la direction de plusieurs projets d’envergure, réalisés au niveau régional, national ou international, qu’il s’agisse de l’organisation d’événements ou de productions innovantes. Elle intervient lors de toutes les phases d’un projet, de l’idée à la réalisation finale avec un objectif affirmé de qualité, d’originalité, et d’efficacité. D’autres experts qui poursuivent les mêmes buts sont associés selon les besoins.</p>
				</motion.div>
			</div>
		</section>
	);
};

export default Services;
